import { Currency, Pair, Token } from '@starex-dex/sdk'
import { Button, ChevronDownIcon, Text, useModal, Flex, Box, MetamaskIcon } from 'packages/uikit'
import styled from 'styled-components'
import { registerToken } from 'utils/wallet'
import { isAddress } from 'utils'
import { useTranslation } from 'contexts/Localization'
import { WrappedTokenInfo } from 'state/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { isMobile } from 'react-device-detect'
import { FlexGap } from 'components/Layout/Flex'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import { Input as NumericalInput } from './NumericalInput'
import { CopyButton } from '../CopyButton'

const CurrencySelectButton = styled(Button).attrs({ variant: 'text' })`
  height: auto;
  background: transparent;
  padding: 10px;
  position: relative;
  z-index: 2;
  padding-left: 0px;
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;

  width: 100%;
  position: relative;
  height: 55px;
  background: rgba(217, 217, 217, 1);
  border-radius: 8px;
`
const InputPanel = styled.div`
  position: relative;
  background-color: transparent;
  z-index: 1;
  width: 100%;
`
const Container = styled.div`
  border-radius: 16px;
  background-color: transparent;
  width: 100%;
`

const StyledBox = styled(Box)`
  background: transparent;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  ${({ theme }) => theme.mediaQueries.xxs} {
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    flex-direction: unset;
  }
`

const StyledButton = styled(Button).attrs({ width: ['fit-content', , , , '50px'] })`
  background: transparent;
  height: 25px;
  padding: 0;

  &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
    opacity: unset;
  }

  &:active:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
    opacity: unset;
    transform: translateY(1px);
    box-shadow: none;
  }
`

const Position = styled.div`
  display: flex;
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  showDoubleLogo?: boolean
  showTokenLogo?: boolean
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton = true,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  showDoubleLogo = true,
  showTokenLogo = true,
}: CurrencyInputPanelProps) {
  const { account, library } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()

  const token = pair ? pair.liquidityToken : currency instanceof Token ? currency : null
  const tokenAddress = token ? isAddress(token.address) : null

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />,
  )

  const balance = parseFloat(selectedCurrencyBalance?.toSignificant(6))

  const onPercent = (percent) => {
    if (percent >= 0 && percent <= 1 && balance !== undefined) {
      const percentValue = balance * percent
      onUserInput(percentValue.toString())
    }
  }

  const textSelect = isMobile ? 'Select' : 'Select'

  return (
    <StyledBox position="relative" id={id}>
      <Flex width={isMobile ? '100%' : '100%'} alignItems="start" flexDirection="column">
        <FlexGap alignItems="center" justifyContent="space-between" width="100%">
          <Flex>
            <CurrencySelectButton
              className="open-currency-select-button"
              selected={!!currency}
              onClick={() => {
                if (!disableCurrencySelect) {
                  onPresentCurrencyModal()
                }
              }}
            >
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Flex alignItems="center">
                  {pair ? (
                    showDoubleLogo ? (
                      <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                    ) : null
                  ) : currency ? (
                    showTokenLogo ? (
                      <CurrencyLogo
                        currency={currency}
                        size="28px"
                        style={{ marginRight: '8px', borderRadius: '50%' }}
                      />
                    ) : null
                  ) : null}

                  {pair ? (
                    <Text color="black" id="pair" mt="2px" fontSize={['10px', , , , '14px']}>
                      {pair?.token0.symbol}:{pair?.token1.symbol}
                    </Text>
                  ) : (
                    <Text color="black" id="pair" mt="2px" fontSize={['10px', , , , '14px']}>
                      {(currency && currency.symbol && currency.symbol.length > 20
                        ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                            currency.symbol.length - 5,
                            currency.symbol.length,
                          )}`
                        : currency?.symbol) || t(`${textSelect}`)}
                    </Text>
                  )}
                </Flex>

                {!disableCurrencySelect && <ChevronDownIcon width={28} color="black" />}
              </Flex>
            </CurrencySelectButton>
            {token && tokenAddress ? (
              <FlexGap alignItems="center" gap="6px">
                <CopyButton
                  width="16px"
                  buttonColor="white"
                  text={tokenAddress}
                  tooltipMessage={t('Token address copied')}
                  tooltipTop={-20}
                  tooltipRight={40}
                  tooltipFontSize={12}
                />
                {library?.provider?.isMetaMask && (
                  <MetamaskIcon
                    style={{ cursor: 'pointer' }}
                    width="16px"
                    onClick={(e) => {
                      e.stopPropagation()
                      registerToken(
                        tokenAddress,
                        token.symbol,
                        token.decimals,
                        token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                      )
                    }}
                  />
                )}
              </FlexGap>
            ) : null}
          </Flex>

          {account && (
            <Position>
              <StyledButton style={{ marginRight: '5px' }} onClick={() => onPercent(0.25)} scale="xs">
                <Text fontSize={['9px', , , , '12px']} color="primary">
                  25%
                </Text>
              </StyledButton>
              <StyledButton style={{ marginRight: '5px' }} onClick={() => onPercent(0.5)} scale="xs">
                <Text fontSize={['9px', , , , '12px']} color="primary">
                  50%
                </Text>
              </StyledButton>
              <StyledButton style={{ marginRight: '5px' }} onClick={() => onPercent(0.75)} scale="xs">
                <Text fontSize={['9px', , , , '12px']} color="primary">
                  75%
                </Text>
              </StyledButton>
              <StyledButton onClick={() => onPercent(1)} scale="xs" display={['none', , , , 'block']}>
                <Text fontSize={['9px', , , , '12px']} color="primary">
                  {t('Max').toLocaleUpperCase(locale)}
                </Text>
              </StyledButton>
            </Position>
          )}
        </FlexGap>

        <Flex flexDirection="column" width="100%">
          <Flex width="100%" position="relative">
            <InputPanel>
              <Container>
                <LabelRow>
                  <NumericalInput
                    className="token-amount-input"
                    value={value}
                    onUserInput={(val) => {
                      onUserInput(val)
                    }}
                    align="left"
                    fontSize={['12px', , , , '16px']}
                  />

                  {showMaxButton ? (
                    <StyledButton onClick={onMax} scale="xs">
                      {t('Max')}
                    </StyledButton>
                  ) : null}
                </LabelRow>
              </Container>
            </InputPanel>
          </Flex>

          <Flex mt="6px" alignItems="center" justifyContent="flex-end">
            {account && (
              <Text
                color="black"
                fontSize={['9px', , , , '12px']}
                bold
                style={{ whiteSpace: 'nowrap', display: 'inline' }}
              >
                {!hideBalance && !!currency
                  ? t('Balance: %balance%', { balance: selectedCurrencyBalance?.toSignificant(6) ?? t('Loading') })
                  : null}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
    </StyledBox>
  )
}
